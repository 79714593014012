<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
      class="py-4"
    >
      <div class="h3 mb-4 mr-auto">
        Прайсы
      </div>

      <b-alert
        show
        dismissible
        variant="info"
      >
        <h5 class="alert-heading">
          Для успешного импорта необходимо соблюсти очередность столбцов в
          файле!
        </h5>

        <p class="m-0">
          <b-table-simple
            small
            responsive
            borderless
            class="table mb-2"
          >
            <b-tbody>
              <b-tr>
                <b-td>
                  Код <span class="text-danger">*</span>
                </b-td>
                <b-td>
                  Наименование <span class="text-danger">*</span>
                </b-td>
                <b-td>
                  Цена <span class="text-danger">*</span>
                </b-td>
                <b-td>
                  Агентское вознаграждение <sup class="text-secondary">необязательный столбец</sup>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </p>

        <p class="m-0">
          Загрузка файла с неправильной очередностью повлечет за собой
          искажение данных прайса
        </p>
      </b-alert>

      <div class="d-flex">
        <v-select
          v-model="data.clinicId"
          :reduce="(clinic) => clinic.id"
          :options="clinics"
          placeholder="Выберите клинику"
          label="title"
          class="price-select bg-white rounded w-100 borderles"
          style="max-width: 100%"
          @input="setSelected(data.clinicId)"
        />

        <!-- <b-form-input
          ref="inputDate"
          v-model="data.startDate"
          :type="inputDateType"
          :min="minDateForInput"
          :max="maxDateForInput"
          placeholder="Выберите дату прайса"
          style="width: 230px; flex-shrink: 0"
          class="ml-2"
          @change="onInputDate"
          @click="onInputDateClick"
        /> -->
      </div>

      <div class="d-flex mt-2">
        <b-form-file
          v-model="data.file"
          accept=".xlsx, .xls, .csv"
          placeholder="Выберите файл или перенесите сюда..."
          drop-placeholder="Перенесите файл сюда..."
          browse-text="Выбрать файл"
          style="width: 470px; flex-shrink: 0"
          class="mr-2"
        />

        <v-select
          v-model="data.type"
          :reduce="(type) => type.id"
          :options="refreshTypes"
          placeholder="Выберите тип прайса"
          label="title"
          class="price-select bg-white rounded w-100 mr-2"
        />

        <v-select
          v-model="data.importType"
          :reduce="(type) => type.id"
          :options="importTypes"
          placeholder="Выберите тип импорта"
          label="title"
          class="price-select bg-white rounded w-100 mr-2"
        />

        <b-button
          variant="primary"
          class="ml-auto"
          :disabled="buttonDisabled"
          size="sm"
          style="flex-shrink: 0"
          :type="$const.PRIMARY_BUTTON"
          @click="onPriceImport"
        >
          Добавить прайс
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import { clinicService } from '@/services';
import { showSuccessCustomMessage, showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'Prices',
  page: {
    title: 'CRM Doctis - Прайсы',
  },
  components: {},
  data() {
    return {
      // inputDateType: 'text',
      isLoading: false,
      clinics: [],
      types: [
        {
          id: 0,
          title: 'ДМС',
        },
        {
          id: 1,
          title: 'Индивидуальный',
        },
        {
          id: 2,
          title: 'Продукт в коробке',
        },
        {
          id: 3,
          title: 'Телемед',
        },
      ],
      importTypes: [
        {
          id: 0,
          title: 'Создание',
        },
        // {
        //   id: 1,
        //   title: 'Обновление',
        // },
        {
          id: 2,
          title: 'Удаление',
        },
      ],
      selectedClinic: [],
      data: {
        clinicId: null,
        // startDate: null,
        type: null,
        importType: null,
        file: null,
        clinicNetworkId: null,
      },
    };
  },
  computed: {
    buttonDisabled() {
      return this.isLoading || this.data.clinicId == null || this.data.type == null || this.data.importType == null || this.data.file == null;
    },
    // minDateForInput() {
    //   return new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0];
    // },
    // maxDateForInput() {
    //   return new Date(new Date().setFullYear(new Date().getFullYear() + 5)).toISOString().split('T')[0];
    // },
    refreshTypes() {
      const clinic = this.selectedClinic[0];

      if (clinic?.clinicNetworkId === 1 && clinic?.clinicNetworkTitle === 'Мать и Дитя') {
        return this.types.filter((el) => el.id !== 2);
      }

      return this.types;
    },
  },
  async created() {
    this.isLoading = true;
    const clinics = await clinicService.getListNames();
    this.clinics = clinics.map((clinic) => ({ ...clinic, title: `${clinic.name}, ${clinic.clinicAddress}` }));
    this.isLoading = false;
  },
  methods: {
    // onInputDate() {
    //   const isValidDate = new Date(this.data.startDate) >= new Date(this.minDateForInput)
    //   && new Date(this.data.startDate) < new Date(this.maxDateForInput);
    //   if (!isValidDate) {
    //     this.data.startDate = this.minDateForInput;
    //   }
    // },
    // async onInputDateClick() {
    //   this.inputDateType = 'date';
    //   await this.$nextTick();
    //   this.$refs.inputDate.focus();
    // },
    async onPriceImport() {
      try {
        this.isLoading = true;

        const responseData = await this.sendPriceClinic();

        if (responseData.status === 'success') {
          this.$store.commit(
            this.$types.OPEN_MODAL,
            {
              name: 'StatusRequestModal',
              props: {
                text: responseData.data ? `Количество успешно загруженных прайсов - ${responseData.data.successCount} ` : '',
                fileErrorKey: responseData.data.fileErrorKey,
              },
            },
          );
        }

        this.data = {
          clinicId: null,
          // startDate: null,
          type: null,
          importType: null,
          file: null,
        };

        // this.inputDateType = 'text';
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendPriceClinic() {
      try {
        const data = await clinicService.sendPrice(this.data);
        showSuccessCustomMessage('Прайс добавлен.');
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось добавить прайс.');
        console.warn(err);
        throw err;
      }
    },
    setSelected(value) {
      this.selectedClinic = this.clinics.filter((el) => el.id === value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.price-select {
  .vs__dropdown-toggle {
    height: 100%;
  }

  .vs__dropdown-menu {
    max-height: 350px;
  }

  .vs__dropdown-option {
    word-break: break-all;
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }
}

.table {
  color: inherit!important;;
}
</style>
